import { Fragment, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import styled, { css, createGlobalStyle } from 'styled-components';
import { AnimatePresence, filterProps, motion } from 'framer-motion';

import theme, {bp, color} from 'settings/theme';

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  mobileOnly?: boolean,
  children?: React.ReactNode
}

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
}

const variants2 = {
  visible: { opacity: 1, translateY: 0 },
  hidden: { opacity: 0, translateY: 100 }
}

const BottomSheet: React.FC<Props> = (props) => {
    
  return (
    <StyledBottomSheet className="bottomSheet">
      
      <AnimatePresence>
        {props.isOpen &&
          <Dialog
            open={true}
            onClose={() => props.setIsOpen(false)}
            className={"bottomSheet-dialog"}
          >
            <StyledBottomSheetWrapper className={`${props.mobileOnly ? 'bottomSheet--mobileOnly' : ''}`}>
              <motion.div
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={{duration: 0.1}}
              >

                {/* Backdrop */}
                <StyledBottomSheetBackdrop className="bottomSheetBackdrop" onClick={() => props.setIsOpen(!props.isOpen)} />

                {/* Panel */}
                <StyledBottomSheetPanel className="bottomSheetWrapper" onClick={() => props.setIsOpen(!props.isOpen)}>
                  <div className="bottomSheetInner">
                    <motion.div
                      variants={variants2}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={{type: 'spring', duration: 0.3}}
                      className="bottomSheetContent"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {props.children}
                    </motion.div>
                  </div>
                </StyledBottomSheetPanel>

              </motion.div>
            </StyledBottomSheetWrapper>

          </Dialog>
        }
        
      </AnimatePresence>
    </StyledBottomSheet>
  );

};

export default BottomSheet;


const StyledBottomSheet = styled.div``

const StyledBottomSheetWrapper = styled.div`

  @media (min-width: ${bp(3)}) {
    &.bottomSheet--mobileOnly{
      display: none;
    }
  }

`

const StyledBottomSheetBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(5px);
`

const StyledBottomSheetPanel = styled.div`

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  .bottomSheetInner{
    width: 100%;
    min-height: 100%;
    
  }

  .bottomSheetContent{
    width: 100%;
    overflow-y: auto;
    margin-bottom: -2rem;
    padding-bottom: 2rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    background-color: ${color.gray(100)};
  }


`
