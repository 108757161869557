import { useMemo, useEffect } from "react";
import styled from "styled-components";

import theme, {bp, color} from 'settings/theme';


type Props = {
  isOpen: boolean,
  setIsOpen: (val: boolean) => void,
  sortBy?: string,
  setSortBy: (val: string) => void
};

const SortBySheet: React.FC<Props> = (props) => {

  // Set the value of the sorting
  const setVal = (val: string) => {
    props.setSortBy(val);
    props.setIsOpen(false);
  }

  return (
    <StyledSortBySheet className="sortBySheet">
      
      <ul className="sortBySheet-options">
        <li>
          <button onClick={() => setVal('created_hi_lo')} className={`sortBySheet-option ${props.sortBy === 'created_hi_lo' ? 'sortBySheet-option--active' : ''}`}>
            <div className="sortBySheet-option-icon">
              <div className="sortBySheet-option-icon-circle">
                <div className="sortBySheet-option-icon-dot"></div>
              </div>
            </div>
            <div className="sortBySheet-option-text">Newest First</div>
          </button>
        </li>
        <li>
          <button onClick={() => setVal('created_lo_hi')} className={`sortBySheet-option ${props.sortBy === 'created_lo_hi' ? 'sortBySheet-option--active' : ''}`}>
            <div className="sortBySheet-option-icon">
              <div className="sortBySheet-option-icon-circle">
                <div className="sortBySheet-option-icon-dot"></div>
              </div>
            </div>
            <div className="sortBySheet-option-text">Oldest First</div>
          </button>
        </li>
        <li>
          <button onClick={() => setVal('price_lo_hi')} className={`sortBySheet-option ${props.sortBy === 'price_lo_hi' ? 'sortBySheet-option--active' : ''}`}>
            <div className="sortBySheet-option-icon">
              <div className="sortBySheet-option-icon-circle">
                <div className="sortBySheet-option-icon-dot"></div>
              </div>
            </div>
            <div className="sortBySheet-option-text">Price: Low to High</div>
          </button>
        </li>
        <li>
          <button onClick={() => setVal('price_hi_lo')} className={`sortBySheet-option ${props.sortBy === 'price_hi_lo' ? 'sortBySheet-option--active' : ''}`}>
            <div className="sortBySheet-option-icon">
              <div className="sortBySheet-option-icon-circle">
                <div className="sortBySheet-option-icon-dot"></div>
              </div>
            </div>
            <div className="sortBySheet-option-text">Price: High to Low</div>
          </button>
        </li>
      </ul>

    </StyledSortBySheet>
  );

};

export default SortBySheet;



const StyledSortBySheet = styled.div<{}>`

  .sortBySheet-options{
    padding: 1rem 0;
  }

  .sortBySheet-option{

    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 1rem;

    .sortBySheet-option-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
    }

    .sortBySheet-option-icon-circle{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: solid 1px ${color.gray(33.3)};
    }

    .sortBySheet-option-icon-dot{
      display: none;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: 50%;
      background-color: ${color.gray(33.3)};
    }

    .sortBySheet-option-text{
      color: black;
      font-size: 0.925rem;
      font-weight: 450;
      padding: 0 1rem 0 0;
    }

    &.sortBySheet-option--active{

      background-color: ${color.gray(88)};

      .sortBySheet-option-icon-dot{
        display: block;
      }

    }

  }


  @media (min-width: ${bp(3)}) {

  }

`

