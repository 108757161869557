export const toCamelCase = (str: string): string => {
    return str
        .split(' ')
        .map((word, index) => {
            // transform the first word all to lower case, the rest to Title Case
            if (index == 0) {
                return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}