import React, { useState, useMemo, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';


import theme, { bp, color } from 'settings/theme';


type Props = {
  harvest: ParsedHarvestDataV2,
  growerName: string
};

const brightness = (color: string) => {
  const [r, g, b] = hexToRgb(color);
  // Convert the RGB values to the range 0-1
  let R = r / 255;
  let G = g / 255;
  let B = b / 255;
  // Convert sRGB values to linear space
  R = R <= 0.03928 ? R / 12.92 : Math.pow((R + 0.055) / 1.055, 2.4);
  G = G <= 0.03928 ? G / 12.92 : Math.pow((G + 0.055) / 1.055, 2.4);
  B = B <= 0.03928 ? B / 12.92 : Math.pow((B + 0.055) / 1.055, 2.4);
  // Calculate the brightness
  const brightness = 0.2126 * R + 0.7152 * G + 0.0722 * B;
  // Return the brightness value
  return brightness;
}

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : [0, 0, 0];
}


// Component
const HarvestPreviewCard: React.FC<Props> = (props) => {

  return (
    <>
      {props.harvest ? (
        <StyledHarvestPreviewCard $backgroundURI={Math.random() * 10 > 5 ? "/images/harvest-preview-background-1.png" : "/images/harvest-preview-background-2.png"} className="harvestCard">

          <div className="harvestCard-details">
            <div className='harvestCard-meta'>
              <h2>
                {props.harvest.nickname}
              </h2>
              <h5>
                by {props.growerName}
              </h5>
            </div>

            <ul>
              <li>
                {props.harvest.taxonomy}
              </li>
              <li>
                THC : {props.harvest.thcContent}%
              </li>
              <li>
                CBD : {props.harvest.cbdContent}%
              </li>
            </ul>

            <Link href={`/harvest/${props.harvest.harvestKey}`}>
              Learn More
            </Link>
          </div>
        </StyledHarvestPreviewCard>
      ) : (
        <div>Product not found</div>
      )}

    </>
  );
};

export default HarvestPreviewCard;


const StyledHarvestPreviewCard = styled.div<{ $backgroundURI: string }>`

  display: flex;
  width: 100%;
  aspect-ratio: 1 / 1;
  color: var(--primary-primary-0, #F7F2ED);
  background: url(${props => props.$backgroundURI});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .harvestCard-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 1rem;
    padding: 0.75rem 0.75rem;
    color: var(--primary-primary-0, #F7F2ED);

    ul{
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;

      color: var(--primary-primary-20, #D9CCBF);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; 

    }

    a{ 
      background: var(--primary-primary-10, #EEE6DD);
      height: 2.25rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      overflow: hidden;
      color: #141313;
      /* UI/Mobile/m-body1-bold */
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;    
      width: 50%;
      text-align: center;
    }

    h2{
      font-size: 3.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem; /* 230.769% */
    }

    h5{
      /* UI/Mobile/m-body2 */
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 1.1375rem */
      margin-bottom: 0.5rem;

    }
    .harvestCard-details-description{
      font-size: 0.925rem;
      line-height: 1.2rem;
      font-weight: 400;
    }

    .harvestCard-details-new{
      padding: 0.4rem 0.9rem;
      color: ${color.gray(100)};
      width: fit-content;
      font-size: 0.75rem;
      font-weight: 400;
      border-radius: 1rem;
      background-color: #23CD80;
    }

    .harvestCard-details-view{
      text-decoration: underline;
    }

    .harvestCard-details-price{
      font-size: 0.925rem;
      font-weight: 350;
    }

    .harvestCard-details-convertedPrice{
      font-size: 0.8rem;
    }

  }

  .harvestCard-buttons{
    flex: 0 0 auto;
    padding-top: 1rem;

    button.harvestCard-button{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 0.5rem;
      width: 100%;
      height: auto;
      line-height: 1rem;
      padding: 0.55rem 0.75rem;
      border: none;
      border-radius: 1rem;
      background-color: ${color.gray(18)};
      transition: background-color 0.15s ease-in-out;

      &:hover{
        background-color: ${color.gray(50)};
      }

      &.harvestCard-button--ghost{
        border: 1px solid ${color.gray(40)};
        background-color: transparent;

        &:hover{
          background-color: ${color.gray(20)};
        }
      }
    }
  }

  @media (min-width: ${bp(2)}) {

    .harvestCard-image{
      border: none;
    }

  }

  @media (min-width: ${bp(3)}) {

    .harvestCard-details{
      padding: 1.5rem 1.5rem;

    }


  }

`;
