import { Connection, PublicKey } from "@solana/web3.js";

export const cachedConnection = new Connection("https://mainnet.helius-rpc.com/?api-key=d406f84c-50a0-4a68-afac-dc094226e921");
export const MINT_ADDRESS = new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");
export const MINT_DECIMALS = 6;

export const growerData: Array<RawGrowerDataV2> = [
    {
        "name": "EdenID: Huon Campbell",
        "symbol": "EDEN",
        "image": "https://arweave.net/YOT_Mkd4q87UL8nTWH4QpAMDk9R2IWrC-BVBO6cXysc?ext=webp",
        "attributes": [
            {
                "trait_type": "Grower Name",
                "value": "Huon Campbell"
            },
            {
                "trait_type": "Farm",
                "value": "Jungle Farms"
            },
            {
                "trait_type": "City",
                "value": "Phuket"
            },
            {
                "trait_type": "Grow Types",
                "value": [
                    "Outdoor",
                    "Greenhouse"
                ]
            },
            {
                "trait_type": "Light Types",
                "value": [
                    "Natural"
                ]
            },
            {
                "trait_type": "Operation Size",
                "value": 3150
            }
        ],
        "social_links": {
            "Line": "@514kagft",
            "Phone": "(+66) 61 476 1750",
            "Email": "junglefarms.th@gmail.com"
        },
        "hero_images": [
            {
                "id": "HCB_1_1",
                "uri": "https://arweave.net/YOT_Mkd4q87UL8nTWH4QpAMDk9R2IWrC-BVBO6cXysc?ext=webp",
                "image_type": "image/webp",
                "image_width": 3648,
                "image_height": 5472,
                "grid_width": 1,
                "grid_height": 1
            }
        ],
        "farm_images": [
            {
                "id": "HCB_2_3",
                "uri": "https://arweave.net/soSE3Nd8T0_PT85acIO58ozhtS51Olwh0YNEjGzRm4M?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 1478,
                "image_height": 1108,
                "grid_width": 2,
                "grid_height": 2
            },
            {
                "id": "HCB_2_2",
                "uri": "https://arweave.net/zO_gIdVt51g1rRL-elI_8Cgo-85x12U3dDOy19iFWXU?ext=webp",
                "image_type": "image/webp",
                "image_width": 3637,
                "image_height": 5456,
                "grid_width": 2,
                "grid_height": 1
            },
            {
                "id": "HCB_2_4",
                "uri": "https://arweave.net/QbU963d8j3D_TEbRkcisnIK16WNOMz0a_-iI_6nAN1w?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 1600,
                "image_height": 1200,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "HCB_2_5",
                "uri": "https://arweave.net/krazmL4NBExTNz8gtDmMU48cu4dPEcc_SbG5rroZh8w?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 960,
                "image_height": 1706,
                "grid_width": 2,
                "grid_height": 1
            },
        ],
        "preview_images": [
            {
                "id": "HCB_3_1",
                "uri": "https://arweave.net/SDXw2ns2sxjoNQkQoVtHn9Udayle1YMx1bZj8eZAk7I?ext=webp",
                "image_type": "image/webp",
                "image_width": 3648,
                "image_height": 5472,
                "grid_width": 1,
                "grid_height": 1
            }
        ]
    }, {
        "name": "EdenID: Jay Woraset",
        "symbol": "EDEN",
        "image": "https://arweave.net/PUiERmPbzRsIVAsf36YDADES5x0rIpXdyE1kERB8nPo?ext=webp",
        "attributes": [
            {
                "trait_type": "Grower Name",
                "value": "Jay Woraset"
            },
            {
                "trait_type": "Farm",
                "value": "How to Fly"
            },
            {
                "trait_type": "City",
                "value": "Bangkok"
            },
            {
                "trait_type": "Grow Types",
                "value": [
                    "Indoor"
                ]
            },
            {
                "trait_type": "Light Types",
                "value": [
                    "LED"
                ]
            },
            {
                "trait_type": "Operation Size",
                "value": 120
            }
        ],
        "social_links": {
            "Line": "@982ztvjq",
            "Phone": "(+66) 64 641 6369",
            "Email": "jayworaset@gmail.com"
        },
        "hero_images": [
            {
                "id": "JDR_1_1",
                "uri": "https://arweave.net/PUiERmPbzRsIVAsf36YDADES5x0rIpXdyE1kERB8nPo?ext=webp",
                "image_type": "image/webp",
                "image_width": 1044,
                "image_height": 1142,
                "grid_width": 2,
                "grid_height": 1
            },
            {
                "id": "JDR_2_6",
                "uri": "https://arweave.net/wJbFaa4NcJHdMhaPbmmdhy0N25ov2T_JXguoElguapQ?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 1280,
                "image_height": 1173,
                "grid_width": 1,
                "grid_height": 1
            },
        ],
        "farm_images": [
            {
                "id": "JDR_2_3",
                "uri": "https://arweave.net/IMUUXyY8iK94FQrIHqRPGmsWC5Ef6zKeiqiAjb3ovw8?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 1280,
                "image_height": 1170,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "JDR_2_4",
                "uri": "https://arweave.net/sd6l3z7JzLO4poyS0Hh5UtBg0t7iR1sDnAG0wDX8VYc?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 1280,
                "image_height": 1169,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "JDR_2_2",
                "uri": "https://arweave.net/O5hiVsMhy0Xygq8P9Kf1fADZv8-1IBgO60Bx5VyjE5o?ext=webp",
                "image_type": "image/webp",
                "image_width": 863,
                "image_height": 1156,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "JDR_2_1",
                "uri": "https://arweave.net/3qUxQKXyqvl7L34neTXxLO1SmU8pFM0OD4huD5TqzpI?ext=webp",
                "image_type": "image/webp",
                "image_width": 1108,
                "image_height": 1478,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "JDR_2_5",
                "uri": "https://arweave.net/X69K3mTymbf2g1v6_RBJDDxSmQ6TOkrVvu7T69pjPpc?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 1280,
                "image_height": 1165,
                "grid_width": 1,
                "grid_height": 1
            },

        ],
        "preview_images": [
            {
                "id": "JDR_3_1",
                "uri": "https://arweave.net/_GNUKXftTQnxA_czlHfK8L_qvhcnIlCjVhBCr_S02RM?ext=webp",
                "image_type": "image/webp",
                "image_width": 1044,
                "image_height": 998,
                "grid_width": 1,
                "grid_height": 2
            },

        ]
    }, {
        "name": "EdenID: Santi Farm",
        "symbol": "EDEN",
        "image": "https://arweave.net/eXJGYs6Z-nM7CbntQ9j8mpTFs_Po7_upxNQeTGYrqnY?ext=jpeg",
        "attributes": [
            {
                "trait_type": "Grower Name",
                "value": "Santi"
            },
            {
                "trait_type": "Farm",
                "value": "Santi Farm"
            },
            {
                "trait_type": "City",
                "value": "Chai Nat"
            },
            {
                "trait_type": "Grow Types",
                "value": [
                    "Indoor"
                ]
            },
            {
                "trait_type": "Light Types",
                "value": [
                    "LED"
                ]
            },
            {
                "trait_type": "Operation Size",
                "value": 200
            }
        ],
        "social_links": {
            "Line": "@0816196169",
            "Phone": "(+66) 081 619 6169",
            "Email": "chalisaphakdee@gmail.com",
        },
        "hero_images": [
            {
                "id": "SAN_1_1",
                "uri": "https://arweave.net/eXJGYs6Z-nM7CbntQ9j8mpTFs_Po7_upxNQeTGYrqnY?ext=jpeg",
                "image_type": "image/jpeg",
                "image_width": 2000,
                "image_height": 2994,
                "grid_width": 2,
                "grid_height": 1
            },
        ],
        "farm_images": [
            {
                "id": "SAN_2_1",
                "uri": "https://arweave.net/mSgMPa3X3I0rdC_Y6r18PEL9sMp-qFA25KQHg3QlHYg?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 2000,
                "image_height": 1336,
                "grid_width": 1,
                "grid_height": 2
            },
            {
                "id": "SAN_2_2",
                "uri": "https://arweave.net/wRiTLtwU-iaBmnPoXEc2Aq2zwO_3n5Fall85MqYxkHI?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 2000,
                "image_height": 1336,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "SAN_2_3",
                "uri": "https://arweave.net/tPpzLVx6VHEb-7xp7srGre2O0M9FetPTxNZSY3QtWNk?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 2000,
                "image_height": 1336,
                "grid_width": 1,
                "grid_height": 1
            },
            {
                "id": "SAN_2_4",
                "uri": "https://arweave.net/aXtT5iBTeKH-ETakdeUwb2kEVhJKx9K7ST8BAL1lXvM?ext=jpg",
                "image_type": "image/jpg",
                "image_width": 2000,
                "image_height": 1336,
                "grid_width": 2,
                "grid_height": 1
            },
        ],
        "preview_images": [
            {
                "id": "SAN_3_1",
                "uri": "https://arweave.net/eXJGYs6Z-nM7CbntQ9j8mpTFs_Po7_upxNQeTGYrqnY?ext=jpeg",
                "image_type": "image/jpeg",
                "image_width": 2000,
                "image_height": 2994,
                "grid_width": 2,
                "grid_height": 1
            },

        ]
    }
]

export const growerHarvestKeyMap = {
    "huoncampbell": [
        "GUfqRUcg3dbwvGaWw6tTQxB8SASRohxHqan2xeG15cAh",
    ],
    "jayworaset": [
        "Cxc3PnWSrAJfbbJdLtJ91RrrHVc2bFC4yCYiyqMwaqve"
    ],
    "santi": [
        "4vacsi87QMjt5vbdp7d79HUUAqMAaJtvBV6DowmhYSnb"
    ]
}

export const harvestKeys = Object.values(growerHarvestKeyMap).flat();