import { Fragment, ReactElement, useEffect, useState, useMemo } from 'react';
import { GetStaticPaths } from 'next';
import Link from 'next/link';
import Head from 'next/head';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { createServerSideHelpers } from '@trpc/react-query/server';
import { DehydratedState } from '@tanstack/react-query';
import { createContext } from 'server/context';
import { Product, Category } from '@prisma/client';

import { trpc } from 'utils/trpc';
import superJson from 'utils/superJson';
import { appRouter } from 'server/routers/_app';
import getTemplateData from 'utils/getTemplateData';
import usePrevious from 'hooks/usePrevious';

import ShopLayout from 'components/layout/shopLayout';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';
import SimpleButton from 'components/common/buttons/simpleButton';
import CatSortBy from 'components/category/catSortBy';
import BottomSheet from 'components/common/modal/bottonSheet';
import SortBySheet from 'components/category/SortBySheet';

import theme, { bp, color } from 'settings/theme';
import HarvestPreviewCard from 'components/harvests/harvestPreviewCard';
import { growerData, growerHarvestKeyMap } from 'server/utils/constants';
import { deserializeGrower } from 'server/utils/deserialize';
import { toCamelCase } from 'server/utils/format';

const pageSize = 36;

type Props = {
  templateData: TemplateData,
  slug: string,
  category: string,
  subCategories: Category[],
  harvestsString: string,
  totalCount: number,
  page: number,
  trpcState: DehydratedState
};
export const getGrowerName = (harvestKey: string) => {
  const growerKey = Object.entries(growerHarvestKeyMap).find(([key, value]) => value.includes(harvestKey))?.[0];
  const parsedGrowerData = growerData.map((grower) => deserializeGrower(toCamelCase(grower.attributes.find((attribute) => attribute.trait_type == "Grower Name")?.value as string).toLowerCase(), grower))
  const parsedNFT = parsedGrowerData.find((grower) => grower.growerKey === growerKey)
  return parsedNFT?.growerName ?? "Unknown"
}

const HarvestsPage: NextPageExtended<Props> = (props) => {

  const router = useRouter();

  // Page
  const page = useMemo(() => {
    return router.query?.page && !isNaN(parseInt(router.query.page as string)) ? parseInt(router.query.page as string) : 1;
  }, [router.query?.page, router.isReady]);

  // Sorting
  const sortBy = useMemo(() => {
    return (router.query?.sort_by as SearchSortBy) ?? 'price_lo_hi'
  }, [router.query?.sort_by, router.isReady]);

  const [isSortByOpen, setIsSortByOpen] = useState<boolean>(false);

  const [harvestsPages, setHarvestsPages] = useState<Record<string, ParsedHarvestDataV2[]>>({});
  // const [productPagesLoaded, setProductPagesLoaded] = useState(false);
  const [harvestsPagesLoaded, setHarvestsPagesLoaded] = useState(false);
  const harvestsQuery = trpc.harvests.get.useQuery({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string) });

  // const totalCount = productsQuery.data?.meta?.total_count ?? 0;
  const harvests = harvestsQuery.data;
  const totalCount = harvests?.length ?? 0;
  // const harvests = useMemo(() => {
  //   return superJson.parse(props.harvestsString) as Product[];
  // }, [props.harvestsString]);

  // Every time we get new results, add them to the harvestsPages object
  useEffect(() => {
    const newHarvestsPages = {
      ...harvestsPages,
      [props.page]: harvests ?? [],
    }
    setHarvestsPages(newHarvestsPages);
    setHarvestsPagesLoaded(true);
  }, [harvests]);


  // Store the current category so we can provide a back button on the product page (This is a big hack, but there's not really a better way to do it)
  useEffect(() => {
    if (typeof window == 'undefined') return;
    window.lastKnownCategory = props.category;
  }, [props.category]);



  const harvestsResults = useMemo(() => {
    // Convert the productPages object into an array of page objects with the page number as a key
    let harvestsPagesArray = Object.entries(harvestsPages).map(([page, harvests]) => {
      return {
        page: parseInt(page),
        harvests,
      }
    });

    // Sort the array by page number
    harvestsPagesArray = harvestsPagesArray.sort((a, b) => a.page - b.page);

    // Return the array of page objects
    return harvestsPagesArray;

  }, [harvestsPages]);


  // Remember the previous Category ID
  const prevCategoryId = usePrevious(props.category ?? "");

  // Sort by opened/closed
  const handleSortByOpenChange = (val: boolean) => {
    setIsSortByOpen(val);
  };


  // Sort By Changed
  const handleSortByChange = (e) => {

    const val = e.target.value;
    const newQuery = { ...router.query };

    if (val === 'price_lo_hi') {
      delete newQuery.sort_by;
    }
    else {
      newQuery.sort_by = val;
    }

    router.push({ href: `/shop/${props.category}`, query: newQuery });

  };


  // Page Changed
  const handlePageChange = (changeVal) => {

    const newQuery = { ...router.query };

    const newPageNumber = harvestsResults[0]?.page + changeVal;

    router.push({ href: `/shop/${props.category}${newPageNumber === 1 ? `` : `/${newPageNumber}`}`, query: newQuery });

  };


  const nextHref = `/shop/${props.category}/${harvestsResults[harvestsResults.length - 1]?.page + 1}`;


  return (
    <>

      <Head>
        <title>Harvests : Eden</title>
      </Head>

      <StyledHarvestIndexPage className="harvestIndexPage">

        {/* Top Section  */}
        <div className='harvestIndexPage-hero'>
          <h1>
            Strains Identified
          </h1>
          <h6>
            Here is our full catalogue list of strains that have registered their Eden ID with us, and where you can buy them!
          </h6>
        </div>
        {/* Load Previous */}
        {/* {productResults && productResults[0]?.page > 1 &&
          <div className="harvestIndexPage-pagination-previous">
            <SimpleButton as="link" href={`/shop/${props.category}${(productResults[0]?.page) === 2 ? `` : `/${(productResults[0]?.page) - 1}`}`} scroll={false} replace={true} buttonStyle="secondary" fullWidth={true}>
              Load Previous
            </SimpleButton>
          </div>
        } */}

        {/* Load Previous */}
        {harvestsResults && harvestsResults[0]?.page > 1 &&
          <div className="harvestIndexPage-pagination-previous">
            <SimpleButton as="link" href={`/shop/${props.category}${(harvestsResults[0]?.page) === 2 ? `` : `/${(harvestsResults[0]?.page) - 1}`}`} scroll={false} replace={true} buttonStyle="secondary" fullWidth={true}>
              Load Previous
            </SimpleButton>
          </div>
        }


        {/* Harvests */}
        <MaxWidthWrapper className="harvestIndexPage-products">
          {harvestsPagesLoaded && harvestsResults && harvestsResults?.length > 0 ? harvestsResults.map((harvestsPages) => (
            <Fragment key={harvestsPages.page}>
              {harvestsPages.harvests && harvestsPages.harvests.map((harvest) => (
                <div key={harvest.harvestKey} className="harvestIndexPage-product">
                  <HarvestPreviewCard harvest={harvest} growerName={getGrowerName(harvest.harvestKey)} />
                </div>

              ))}
            </Fragment>
          )) : (
            <>
              {harvests && harvests.map((harvest) => (
                <div key={harvest.harvestKey} className="harvestIndexPage-product">
                  <HarvestPreviewCard harvest={harvest} growerName={getGrowerName(harvest.harvestKey)} />
                </div>
              ))}
            </>
          )}


        </MaxWidthWrapper>


        {/* Load More */}
        {/* {productResults && Math.ceil((totalCount / pageSize)) > productResults[productResults.length - 1]?.page &&
          <div className="harvestIndexPage-pagination-more">

            <SimpleButton as="link" href={nextHref} scroll={false} replace={true} buttonStyle="secondary" fullWidth={true}>
              Load More +
            </SimpleButton>

          </div>
        } */}
        {/* Load More */}
        {harvestsResults && Math.ceil((totalCount / pageSize)) > harvestsResults[harvestsResults.length - 1]?.page &&
          <div className="harvestIndexPage-pagination-more">

            <SimpleButton as="link" href={nextHref} scroll={false} replace={true} buttonStyle="secondary" fullWidth={true}>
              Load More +
            </SimpleButton>

          </div>
        }

        {/* Sort By Bottom Sheet */}
        <BottomSheet isOpen={isSortByOpen} setIsOpen={handleSortByOpenChange} mobileOnly={true}>
          <SortBySheet isOpen={isSortByOpen} setIsOpen={handleSortByOpenChange} sortBy={sortBy} setSortBy={handleSortByChange} />
        </BottomSheet>

      </StyledHarvestIndexPage>

    </>
  );

}

export const getServerSideProps = async ({ params }) => {

  const categorySlug: string = params?.categorySlug ?? "Harvests";
  const page: number = params?.page ? parseInt(params?.page) : 1;

  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext(),
    transformer: superJson
  });


  const harvestsQuery = ssg.harvests.get.fetch({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string) });

  const results = await Promise.all([
    getTemplateData(),
    harvestsQuery,
  ]);

  const templateData = results[0];
  const harvests = results[1];
  const totalCount = harvests?.length ?? 0;

  // console.log("harvests", harvests);

  return {
    props: {
      templateData,
      slug: categorySlug,
      // category,
      harvestsString: superJson.stringify(harvests),
      totalCount: totalCount,
      page,
      trpcState: ssg.dehydrate(),
    },
    // revalidate: 1
  };
};

HarvestsPage.getLayout = (page: ReactElement) => {
  return (
    <ShopLayout templateData={page.props.templateData}>
      {page}
    </ShopLayout>
  )
};

export default HarvestsPage;

const StyledHarvestIndexPage = styled.div`
  h1{
    color: var(--primary-primary-0, #F7F2ED);

    font-size: 3.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem
  }

  h6{
    color: var(--primary-primary-20, #D9CCBF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
  }

  .harvestIndexPage-hero{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    background: url("/images/harvest-index-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-opacity: 0.8;
    background-position: center;
  }


  /* Products */
  .harvestIndexPage-products{
    display: grid;
    grid-template-columns: 1fr;
    /* column-gap: 0.75rem; */
    row-gap: 0.5rem;
    padding: 0.5rem 0;
  }
  /* Pagination */
  .harvestIndexPage-pagination-previous, .harvestIndexPage-pagination-more{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;
  }

  @media (min-width: 40rem) {

    .harvestIndexPage-products{
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 3rem;
    }

  }

  @media (min-width: ${bp(3)}) {
    
    h1{
      font-size: 6rem;
      line-height: 100%;
      text-align: center;
      width: 30%;
    }

    h6{
      font-size: 1.5rem;
      line-height: 150%;
    }

    .harvestIndexPage-products{
      margin-top: 1.5rem;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
  }
  }

`;