import { JsonMetadata, Metadata } from "@metaplex-foundation/js";

export const deserializeImage = (image: RawImage): ParsedImage | ParsedGrowerImage => ({
    id: image.id,
    uri: image.uri,
    imageType: image.image_type,
    imageWidth: image.image_width,
    imageHeight: image.image_height,
    gridWidth: image.grid_width,
    gridHeight: image.grid_height,
});

export const deserializeHarvest = (harvestKey: string, harvest: RawHarvestDataV2): ParsedHarvestDataV2 => {
    const attributes = harvest.attributes.reduce((acc, attr) => {
        acc[attr.trait_type] = attr.value;
        return acc;
    }, {});

    return {
        harvestKey,
        metadataName: harvest.name,
        metadataImage: harvest.image ?? '',
        strainName: attributes['Strain Name'],
        strainId: attributes['Strain ID'],
        nickname: attributes['Nickname'],
        thcContent: attributes['THC Content'],
        cbdContent: attributes['CBD Content'],
        bestFor: attributes['Best for'],
        taxonomy: attributes['Taxonomy'],
        moods: attributes['Moods'],
        dominantTerpene: attributes['Dominant Terpene'],
        harvestNumber: attributes['Harvest Number'],
        harvestDate: attributes['Harvest Date'],
        harvestWeight: attributes['Harvest Weight'],
        parent1: attributes['Parent 1'],
        parent2: attributes['Parent 2'],
        labResults: harvest.lab_results,
        hexColor: harvest.hex_color,
        harvestTimestamp: harvest.harvest_timestamp,
        heroImages: harvest.hero_images.map((rawImage) => deserializeImage(rawImage)),
        packagingImages: harvest.packaging_images.map((rawImage) => deserializeImage(rawImage)),
        flowerImages: harvest.flower_images.map((rawImage) => deserializeImage(rawImage)),
    };
}

export const deserializeGrower = (growerKey: string, grower: RawGrowerDataV2): ParsedGrowerDataV2 => {
    const attributes = grower.attributes.reduce((acc, attr) => {
        acc[attr.trait_type] = attr.value;
        return acc;
    }, {});

    return {
        growerKey: growerKey,
        metadataName: grower.name,
        metadataImage: grower.image ?? '',
        growerName: attributes['Grower Name'],
        farmName: attributes['Farm'],
        growerCity: attributes['City'],
        growTypes: attributes['Grow Types'],
        lightTypes: attributes['Light Types'],
        operationSize: attributes['Operation Size'],
        growerSince: 0, // Add appropriate field from ParsedGrowerDataV2
        growerTotalHarvestWeight: 0, // Add appropriate field from ParsedGrowerDataV2
        growerTotalHarvests: 0, // Add appropriate field from ParsedGrowerDataV2
        growerTotalStrains: 0, // Add appropriate field from ParsedGrowerDataV2
        growerHeroImages: grower.hero_images.map((rawImage) => deserializeImage(rawImage)) as Array<ParsedGrowerImage>,
        growerFarmImages: grower.farm_images.map((rawImage) => deserializeImage(rawImage)) as Array<ParsedGrowerImage>,
        growerPreviewImages: grower.preview_images.map((rawImage) => deserializeImage(rawImage)) as Array<ParsedGrowerImage>,
        growerHarvests: [], // Add appropriate field from ParsedGrowerDataV2,
        socialLinks: grower.social_links,
    };

}


export const isHarvestMetadata = (value: any): value is HarvestMetadata =>
    typeof value === 'object';